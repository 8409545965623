/* Media query for Chrome */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .icon {
    /* Styles for Chrome */
    margin-top: -8px;
  }
}

/* Media query for Safari */
@supports (-webkit-backdrop-filter: none) {
  .icon {
    /* Styles for Safari */
    margin-top: -2px;
  }
}
  
  /* Media query for Firefox */
  @-moz-document url-prefix() {
    .icon {
      /* Styles for Firefox */
      margin-top: -8px; /* Adjust the margin-top value as desired */
    }
  }
  
  
  